const preparedLink = (page: string, params: string) => {
	const preparedParams = params ? params.replace(/^\/+/, "").replace(/\/*$/, "") : "";

	if (preparedParams) {
		return `/${page}/${preparedParams}`;
	}
	return `/${page}`;
};

export default preparedLink;
